.errornote {
  @extend .alert;
  @extend .alert-danger;
}
.messagelist {
  @include reset-list();
  display: block;
  margin: 1rem $grid-gutter-width-base 0;
  li {
    @extend .alert;
    &:last-child {
      margin-bottom: 0;
    }
    &.success {
      @extend .alert-success;
    }
    &.info {
      @extend .alert-info;
    }
    &.warning {
      @extend .alert-warning;
    }
    &.danger, &.error {
      @extend .alert-danger;
    }
  }
}
