.breadcrumbs {
  font-size: $font-size-sm;
  color: $header-muted-color;
  position: relative;
  li {
    float: left;
  }
  a {
    color: $link-color;
    display: inline-block;
    margin: 0 .2rem;
    &:first-child {
      margin-left: 0;
    }
  }
}

body.suit_layout_vertical {
  #container {
    > .breadcrumbs {
      display: block;
      position: absolute;
      padding: 1.5rem $grid-gutter-width-base;
      left: $vertical-menu-width;
    }
    #content {
      .breadcrumbs {
        display: none;
      }
      .messagelist {
        margin: 0 0 $grid-gutter-width-base*0.5 0;
      }
    }
  }
  #container {
    > .messagelist {
      display: none;
    }
  }
  /*&.change-form {
    #container {
      > .breadcrumbs {
        display: none;
      }
    }
    #content {
      .breadcrumbs {
      }
    }
  }*/
}

body.suit_layout_horizontal {
  .breadcrumbs {
    padding: 1.5rem $nav-padding-horizontal 0;
  }
  &.change-list {
    .breadcrumbs {
      display: none;
    }
  }
  &.change-form {
    #content {
      .breadcrumbs {
        display: none;
        padding: 1.5rem 0;
        position: relative;
        z-index: 6;
        li {
          float: left;
        }
      }
    }
  }
  #content {
    .content-wrap {
      .messagelist {
        display: none;
      }
    }
  }
}
