
.btn-round {
  border-radius: 40px;
}
@mixin btn-mixin($state, $icon: none, $size: none, $round: false) {
  display: inline-block;
  @extend .btn;
  @extend .btn-#{$state};
  @if $size {
    @extend .btn-#{$size} !optional;
  }
  @if $round {
    border-radius: 70px;
  }
  @if $icon {
    &:before {
      margin-right: 3px;
      display: inline-block;
      @include fa-icon-font();
      content: $icon;
    }
  }
}

.btn-outline-danger {
  border-color: transparentize($brand-danger, .25);
  background-color: #fff;
}
