body.change-form {
  #content-main {
    > .object-tools {
      float: right;
      margin-top: -2.85rem;
      margin-bottom: 20px;
      overflow: hidden;
      li {
        display: inline-block;
        &.list-item {
          display: none;
        }
      }
    }
    > .object-tools + form {
      width: 100%;
      clear: both;
    }
  }
  .edit-row {
    display: flex;
    flex-wrap: wrap;
    > div {
      margin: 0 $grid-gutter-width-base*0.5;
    }
  }
  &:not(.suit_form_submit_on_right){
    .edit-row {
      > div {
        &.col-left, &.col-right {
          flex: 1;
          flex-basis: 100%;
        }
        &.col-right {
          .object-tools {
            display: none;
          }
        }
      }
    }
  }
  &.suit_form_submit_on_right {
    // Hide object-tools from top in 2column layout
    #content-main {
      > .object-tools {
        margin-top: 0;
        li.heading {
          display: none;
        }
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
    }
    .edit-row {
      display: flex;
      > div {
        margin: 0 $grid-gutter-width-base*0.5;
        &.col-left {
          flex: 1;
        }
        &.col-right {
          width: 22%;
          @include media-breakpoint-only(lg) {
            width: 25%;
          }
          @include media-breakpoint-down(lg) {
            width: auto;
            flex: 1;
            flex-basis: 100%;
          }
        }
      }

    }
  }
}
// History table
table#change-history {
  @extend .table;
  @extend #result_list;
  thead {
    > tr th {
      padding: $table-th-padding !important;
    }
  }
}
