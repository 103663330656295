@mixin fa-icon-font() {
  font-family: FontAwesome;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
}

$icon-ok: "\f00c";
$icon-remove: "\f00d";
$icon-cog: "\f013";
$icon-home: "\f015";
$icon-plus: "\f067";
$icon-plus-circle: "\f055";
$icon-pencil: "\f040";
$icon-chevron-up: "\f077";
$icon-chevron-down: "\f078";
$icon-search: "\f002";
$icon-calendar: "\f073";
$icon-calendar-o: "\f133";
$icon-time: "\f017";
.link-with-icon {
  .fa {
    vertical-align: text-bottom;
    margin-left: 3px;
  }
}
