//Migration Bootstrap5

a:not([myclasstoignore]) {
    text-decoration: none;
}

a:not([myclasstoignore]):hover {
    text-decoration: underline;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  appearance: auto;
}

.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

label {
  margin-bottom: 0.5rem;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}