@use "sass:math";

body.suit_layout_horizontal {
  #suit-nav {
    flex-basis: 100%;
    margin-top: $header-padding-vertical;
    background-color: $top-nav-bg;
    > ul {
      @include reset-list();
      margin: 0 $header-padding-horizontal;
      > li {
        position: relative;
        display: block;
        float: left;
        a {
          display: block;
          //font-size: $font-size-sm;
          color: transparentize(#fff, .3);
          &:hover {
            color: #fff;
            text-decoration: none;
          }
        }
        > a {
          position: relative;
          padding: math.div($header-padding-vertical, 1.5) math.div($header-padding-horizontal, 1.4);
          border-left: 1px solid $header-bg;
        }
        &:last-child {
          > a {
            border-right: 1px solid $header-bg;
          }
        }
        &.active {
          a {
            background-color: #fff;
            color: $body-color;
            font-weight: bold;
            box-shadow: inset 0 3px 0 $link-color-brighter;
            &:hover {
              @include gradient-y(darken($body-bg, 3%), #fff);
            }
          }
        }
        &:hover {
          > a {
            background-color: lighten($top-nav-bg, 5%);
          }
          > ul {
            background-color: lighten($top-nav-bg, 5%);
            display: block;
          }
        }
        > ul {
          @include reset-list();
          z-index: 1000;
          display: none;
          background-color: $top-nav-bg;
          position: absolute;
          min-width: 180px;
          box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, .07);
          font-size: $font-size-sm;
          > li {
            > a {
              display: block;
              padding: $header-padding-vertical*0.5 math.div($header-padding-horizontal, 1.4);
              border-bottom: 1px solid darken($top-nav-bg, 5%);
              &:hover {
                background-color: darken($top-nav-bg, 2%);
              }
            }
          }
        }
      }
      &.suit-nav-right {
        float: right;
        > li > ul {
          right: 0;
        }
      }
    }
  }
  #suit-sub-nav {
    flex-basis: 100%;
    display: block;
    background-color: #fff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
    ul {
      @include reset-list();
      margin: 0 $nav-padding-horizontal;
      > li {
        > a {
          color: $link-color;
          display: block;
          float: left;
          font-size: $font-size-sm;
          padding: math.div($header-padding-vertical, 1.3) 4px;
          margin: 2px math.div($header-padding-horizontal, 3.75) 0;
          margin-bottom: -3px;
        }
        &:first-child {
          a {
            margin-left: 0;
          }
        }
        &.active {
          a {
            color: $body-color;
            font-weight: bold;
            border-bottom: 3px solid $link-color-bright;
          }
        }
      }
    }
  }
}
body.suit_layout_vertical {
  #suit-nav {
    flex-basis: 100%;
    background-color: $top-nav-bg;
    > ul {
      @include reset-list();
      > li {
        position: relative;
        a {
          &:hover, &:focus {
            text-decoration: none;
          }
        }
        &.active {
          //background-color: $link-color-brighter;
          background-color: $header-bg;
          &:after {
            @include fa-icon-font();
            color: $inverse-lightest;
            position: absolute;
            right: 1rem;
            top: .85rem;
            font-size: 9px;
          }
          &.has-children {
            &:after {
              content: "\f078";
            }
          }
          &:not(.has-children) {
            &:after {
              content: "\f054";
            }
          }
          > a {
            &, &:hover {
              color: #fff;
            }
          }
          > ul {
            display: block;
          }
        }
        > a {
          display: block;
          padding: .6rem math.div($header-padding-horizontal, 1.5);
          color: transparentize(#fff, .3);
          .fa {
            margin-right: .7rem;
          }
        }
        &:not(.active) {
          a {
            &:hover {
              color: #fff;
            }
          }
          &:hover {
            background-color: darken($top-nav-bg, 5%);
            > ul {
              box-shadow: 0 0 2px 2px rgba(0, 0, 0, .1);
              top: 0;
              left: 70%;
              display: block;
              position: absolute;
              z-index: 1000;
              padding: 0;
              > li {
                > a {
                  white-space: nowrap;
                  &:hover {
                    background-color: $inverse-lighter;
                  }
                }
              }
            }
          }
        }
        > ul {
          @include reset-list();
          background-color: #fff;
          display: none;
          font-size: 13px;
          padding: 0;
          > li {
            &:not(:last-child){
              border-bottom: 1px solid lighten($body-bg, 2%);
            }
            &.active {
              background-color: #fff;
              a {
                box-shadow: inset 4px 0 0 $link-color-brighter;
                &, &:hover, &:focus {
                  color: $link-color-brighter;
                }
              }
            }
            a {
              color: $header-muted-color;
              display: block;
              padding: .4rem math.div($header-padding-horizontal, 1.5);
              &:hover {
                background-color: lighten($body-bg, 2%);
                color: darken($header-muted-color, 25%);
              }
              //border-bottom: 1px solid $body-bg;
            }
          }
        }
      }
    }
  }
}
